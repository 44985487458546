const isEnter = transitionStatus =>
  ["entering", "entered", "POP"].includes(transitionStatus)

const isExit = transtitionStatus => transtitionStatus === "exiting"

export const typography = (transitionStatus, options) => {
  if (isEnter(transitionStatus)) {
    return {
      to: {
        transform: "translateX(0px)",
        opacity: 1,
      },
      ...options,
    }
  } else if (isExit(transitionStatus)) {
    return {
      to: {
        transform: "translateX(-50px)",
        opacity: 0,
      },
      ...options,
    }
  } else {
    return {
      to: {
        transform: "translateX(50px)",
        opacity: 0,
      },
    }
  }
}

export const colorBlock = (transitionStatus, options) => {
  if (isEnter(transitionStatus)) {
    return {
      from: {
        transformOrigin: "0% 0%",
      },
      to: {
        transform: "scaleX(1)",
      },
      ...options,
    }
  } else if (isExit(transitionStatus)) {
    return {
      from: {
        // transformOrigin: "100% 100%",
      },
      to: {
        transform: "scaleX(0)",
      },
      ...options,
    }
  } else {
    return {
      from: {
        // transformOrigin: "100% 100%",
      },
      to: {
        transform: "scaleX(0)",
      },
    }
  }
}
