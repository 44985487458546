import React from "react"
import { useSpring, animated } from "react-spring"
import { useTransitionState } from "gatsby-plugin-transition-link/hooks"
import { typography } from "../animations/global"

import { fontsizes, fontweights, mq, colors } from "../styles/variables"

const IntroStyles = mq({
  fontSize: fontsizes.medium,
  fontWeight: fontweights.light,
  gridColumn: ["2 / 5", "4 / 7", "6 / 9"],
  marginLeft: [60, 0],
  color: colors.primary,
  zIndex: 2,
})

const ContentIntro = ({ children }) => {
  const { transitionStatus } = useTransitionState()

  const props = useSpring(typography(transitionStatus))
  return (
    <animated.p style={props} css={IntroStyles}>
      {children}
    </animated.p>
  )
}

export default ContentIntro
