import React from "react"
import { useSpring, animated } from "react-spring"
import { useTransitionState } from "gatsby-plugin-transition-link/hooks"

import { colorBlock } from "../animations/global"
import { mq, colors } from "../styles/variables"

const SharedStyles = mq({
  position: "absolute",
  width: "100%",
  top: 0,
  bottom: 0,
  backgroundColor: colors.accent,
  transformOrigin: "100% 100%",
  "&.left": {
    gridColumn: ["2 / 5", "2 / 7", "2 / 9"],
  },
  "&.right": {
    gridColumn: ["3 / 6", "4 / 9", "5 / 11"],
  },
})

const ColorBlock = ({ alternate }) => {
  const { transitionStatus } = useTransitionState()

  const props = useSpring(
    colorBlock(transitionStatus, {
      config: { tension: 500, clamp: true },
    })
  )
  return (
    <animated.div
      style={props}
      css={SharedStyles}
      className={alternate ? "right" : "left"}
    />
  )
}

export default ColorBlock
