import React from "react"
import { useSpring, animated } from "react-spring"
import { useTransitionState } from "gatsby-plugin-transition-link/hooks"
import { typography } from "../animations/global"

import { fontsizes, fontweights, mq, fontfamilies } from "../styles/variables"

const HeadlineStyles = mq({
  textTransform: "lowercase",
  gridColumn: ["2 / 6", "3 / 8", "4 / 10"],
  margin: ["30vh 0 60px 0", "30vh 0 60px 0"],
  position: "relative",
  fontSize: fontsizes.gigantic,
  fontWeight: fontweights.light,
  fontFamily: fontfamilies.sans,
  lineHeight: 1.1,
  textAlign: "right",
  zIndex: 2,
  wordBreak: "break-word",
  WebkitFontSmoothing: "antialiased",
})

const MainHeadline = ({ children }) => {
  const { transitionStatus } = useTransitionState()
  const props = useSpring(typography(transitionStatus, { delay: 150 }))

  return (
    <animated.h1 style={props} css={HeadlineStyles}>
      <span>{children}</span>
    </animated.h1>
  )
}

export default MainHeadline
