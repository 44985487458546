import React from "react"

import { fontsizes, mq, lineheights } from "../styles/variables"

const NoteStyles = mq({
  fontSize: fontsizes.tiny,
  letterSpacing: ".1em",
  textTransform: "lowercase",
  gridColumn: ["2 / 4", "3 / 5"],
  textAlign: ["left", "left", "right"],
  margin: ["0", "0 120px 0 0"],

  ".date": {
    display: "block",
    marginBottom: 10 * lineheights.normal,
  },
  ".tag": {
    display: "block",
  },
})

const ContentNote = ({ children }) => <div css={NoteStyles}>{children}</div>

export default ContentNote
