import React from "react"

import { fontsizes, fontweights, mq, colors } from "../styles/variables"

const SharedStyles = mq({
  fontSize: fontsizes.small,
  fontWeight: fontweights.regular,
  gridColumn: ["2 / 6", "3 / 7", "4 / 8"],
  margin: "20px 0",
  maxWidth: [460, "unset"],
  paddingLeft: 30,
  li: {
    paddingLeft: 10,
    marginBottom: 25,
    zIndex: 2,
  },

  "&.unordered": {
    listStyle: "none",
    paddingLeft: 0,

    li: {
      position: "relative",
      paddingLeft: 30,

      "&:before": {
        content: "''",
        position: "absolute",
        left: 8,
        top: 11,
        height: 3,
        width: 3,
        backgroundColor: colors.primary,
        borderRadius: 10,
      },
    },

    "p + &": {
      marginTop: 0,
    },
  },
  "&.orderNumber": {
    listStyle: "decimal",
  },
  "&.orderAlpha": {
    listStyle: "lower-alpha",
  },
  "&.orderRoman": {
    listStyle: "lower-roman",
  },
})

const ContentList = ({ children, orderNumber, orderAlpha, orderRoman }) => {
  if (orderNumber) {
    return (
      <ol css={SharedStyles} className="orderNumber">
        {children}
      </ol>
    )
  }
  if (orderAlpha) {
    return (
      <ol css={SharedStyles} className="orderAlpha">
        {children}
      </ol>
    )
  }
  if (orderRoman) {
    return (
      <ol css={SharedStyles} className="orderRoman">
        {children}
      </ol>
    )
  }
  return (
    <ul css={SharedStyles} className="unordered">
      {children}
    </ul>
  )
}

export default ContentList
