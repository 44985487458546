import React from "react"

import { fontsizes, fontweights, mq, grids, colors } from "../styles/variables"

const SectionStyles = mq({
  position: "relative",
  display: "grid",
  gridAutoFlow: "column",
  grid: grids.siteColumns,
  gridColumnGap: grids.siteColumnGap,
  alignContent: "start",
  marginBottom: [60, 120],

  ".bodycopy": {
    gridColumn: ["2 / 6", "3 / 7", "4 / 8"],
    maxWidth: [460, "unset"],
    margin: "0 0 30px 0",
    zIndex: 2,
  },
  ".subheader": {
    position: "relative",
    gridColumn: ["2 / 6", "3 / 7", "4 / 8"],
    maxWidth: [460, "unset"],
    fontSize: fontsizes.medium,
    margin: "20px 0",
    zIndex: 2,
    "&:before": {
      content: "''",
      position: "absolute",
      bottom: 7,
      left: 0,
      right: 0,
      height: 7,
      backgroundColor: colors.accent,
      zIndex: -1,
    },
  },
  ".leadin": {
    gridColumn: ["2 / 6", "3 / 7", "4 / 8"],
    maxWidth: [460, "unset"],
    margin: 0,
    fontSize: fontsizes.small,
    fontWeight: fontweights.bold,
    marginBottom: 4,
    zIndex: 2,
  },
})

const ContentSection = ({ children }) => {
  return <section css={SectionStyles}>{children}</section>
}

export default ContentSection
