import React from "react"

import { mq } from "../styles/variables"

const MainStyles = mq({
  // gridArea: "main",
  position: "relative",
})

const PageMain = ({ children }) => <main css={MainStyles}>{children}</main>

export default PageMain
